<template>
    <div ref="colorpicker" class="color-picker-outer" v-if="showPickerContainer">
      <span class="color-picker-inner"
        v-bind:style="{ 'background-color': colorValue }"
        @click="togglePicker"></span>
      <chrome-picker :value="colors" @input="updateFromPicker" v-if="displayPicker" />
    </div>
</template>

<script>
import { Chrome } from 'vue-color';

export default {
  props: {
    fieldName: String,
    initColor: String,
    showPickerContainer: true,
  },
  components: {
    'chrome-picker': Chrome,
  },
  data() {
    return {
      colors: {
        hex: '#4A4A4A',
      },
      colorValue: this.initColor,
      displayPicker: false,
    };
  },
  methods: {
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color,
        };
      } else if (color.slice(0, 4) === 'rgba') {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
        const hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)}`;
        this.colors = {
          hex,
          a: rgba[3],
        };
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      if (this.displayPicker) {
        this.hidePicker();
      } else {
        this.showPicker();
      }
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a === 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue = `rgba(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b}, ${color.rgba.a})`;
      }
    },
    documentClick(e) {
      const el = this.$refs.colorpicker;
      const { target } = e;
      if (el && target) {
        if (el !== target && !el.contains(target)) {
          this.hidePicker();
        }
        this.$emit('update-color', this.colorValue, this.fieldName);
      }
    },
  },
  watch: {
    initColor(newVal, oldVal) {
      this.colorValue = newVal;
    },
  },
};
</script>

<style scoped>
  div.color-picker-outer {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-color: #EEE;
    position: relative;
    top: 19px;
    outline: 3px solid #C9C9C9;
  }

  .color-picker-inner {
    width: 30px;
    height: 30px;
    position: relative;
    display: inline-block;
  }

  .vc-chrome {
    position: absolute;
    top: -3px;
  /*left: 55px; */
  /*bottom: 20px; */
    right: 33px;
    z-index: 9;
  }
</style>
